<template>             
        <ion-card  class="ion-text-center" >
            <ion-card-header>
                <ion-badge color="light">{{order.status}}</ion-badge>
                <h4 style="font-weight: bold; color: black"> {{order.userName}} </h4>
            </ion-card-header>
            <ion-card-content>                       
                <div v-for="orderItem in order.items" :key="orderItem.id" :style="{background: cardColor(orderItem)}">
                    <h2 color="black" style="font-weight: bold; color: black"> {{ orderItem.name }} </h2>
                    <ion-chip v-if="orderItem.status == 'Cancelled' " color="danger" style="font-weight: bold">Cancelled </ion-chip>
                    <div v-if="orderItem.customizations">
                        <ion-chip  v-for="custom in orderItem.customizations" :key="custom" color="primary">
                            <ion-label>{{custom}}</ion-label>
                        </ion-chip>                      
                    </div>

                    <ion-button color="danger" v-if="orderItem.status != 'Cancelled'" @click="changeItemStatus(order.id, orderItem.id, 'Cancelled')"  slot="end">Cancel</ion-button>

                    <ion-button v-if="orderItem.status == 'Complete'" @click="changeItemStatus(order.id, orderItem.id, 'Making')" slot="end">Making</ion-button>
                    <ion-button v-if="orderItem.status == 'Making'" @click="changeItemStatus(order.id, orderItem.id, 'New')" slot="end">New</ion-button>

                    <ion-button v-if="orderItem.status == 'New'" @click="changeItemStatus(order.id, orderItem.id, 'Making')" slot="end">Making</ion-button>
                    <ion-button v-if="orderItem.status == 'Making'" @click="changeItemStatus(order.id, orderItem.id, 'Complete')" slot="end">Complete</ion-button>
                    <ion-button v-if="order.status == 'Finished'" @click="changeOrderStatus(order.id, 'Picked Up')" slot="end">Picked Up</ion-button>
                </div>
            </ion-card-content>
        </ion-card>
</template>



<script>
//import { IonItem, IonThumbnail, IonImg, IonLabel, IonButton, IonItemDivider, IonBadge, IonItemGroup, IonList } from '@ionic/vue';

export default {
    props: ["order"],
    components: {
        // IonItem, 
        // IonThumbnail, 
        // IonImg,
        // IonLabel,
        // IonButton,
        // IonItemDivider, 
        // IonBadge, 
        // IonItemGroup,
        // IonList
    },
    methods: {
        changeItemStatus(orderId, orderItemId, status) {

            this.$store.dispatch("changeItemStatus", {orderId: orderId, orderItemId: orderItemId, status: status});
        },
        changeOrderStatus(orderId, status) {
            this.$store.dispatch("changeOrderStatus", {orderId: orderId, status: status});
        },
        markOutOfStock(menuItemId) {
            this.$store.dispatch('markOutOfStock', {menuItemId: menuItemId, outOfStock: true});
        },
    cardColor(orderItem) {
        if (orderItem.status == 'Making') {
            return '#f8f898'
        } else if (orderItem.status == 'Complete'){
            return '#9EDEC6'
        } else {
            return '#fafafa'
        }
    }

}
}
</script>