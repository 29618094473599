<template>
  <base-layout title="Fulfillment">
    <ion-grid>
          <ion-row class="ion-justify-content-start">
            
              <ion-col  size-md="4"  size-lg="3" size-sm="6" size-xs="6" v-for="item in orders" :key="item.id">
                <OrderListItem :order="item"/>              
          </ion-col>
        </ion-row>
      </ion-grid>
  </base-layout>
</template>

<script>
//import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
//import { IonList } from '@ionic/vue';
import OrderListItem from '../components/OrderListItem.vue';
export default  {
  components: { 
    //IonList, 
    OrderListItem, },
  data() {
    return {
      
    };
  },
  computed: {
    orders() {
      return this.$store.getters.orders;//.filter(o => o.status != 'Finished');
    }
  },
  mounted() {
    if (this.orders == null) {
      this.$store.dispatch('loadOrders');
      this.$store.dispatch('loadNotCancelled');
    }
  }
}
</script>